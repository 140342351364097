<template>
  <b-modal
    v-model="showLocal"
    @hidden="hidden"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    hide-header
    id="rejection-modal"
  >
    <div class="row">
      <div class="col-md-12">
        <section>
          <div
            class="
              modal-header
              d-flex
              justify-content-between
              align-items-center
            "
          >
            <div class="modal-title text-white">Comprobante rechazado</div>
            <b-button
              class="icon-close icon-close-primary"
              @click="hideModal"
              aria-label="Close"
            >
            </b-button>
          </div>
        </section>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 text-center">
        <section class="modal-main-content reject-reason">
          <p><b>Se ha rechazado la factura por los siguientes motivos:</b></p>
            <p class="my-0" v-html="rejectReason"></p>
          <p><b>Debe cargar una nota de crédito para liberar el ID de pago y poder subir una nueva factura.</b></p>
        </section>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { ModalMixin } from "wize-admin";
import { required } from "vuelidate/lib/validators";

export default {
  name: "RejectedVoucherModal",
  props:{
    rejectReason: String
  },
  data() {
    return {
      reasonId: null,
      reasons: [],
    };
  },
  mixins: [ModalMixin],
  methods: {
    confirm() {
      const self = this;
      self.$v.$touch();
      if (self.$v.$error) return;

      this.$emit("confirm");
    },
    close() {
      this.$emit("hide");
    },
  },
  validations: {
    reasonId: { required },
  },
};
</script>

<style lang="stylus" scoped>
</style>
