<template>
  <div class="voucher page-container">
    <div class="page-title-container">
      <h1 class="page-title">Cargar nota de crédito</h1>
    </div>

    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-8">
          <b-card tag="article" no-body class="custom-card">
            <b-card-header
              header-tag="section"
              class="custom-card-header d-flex align-items-center"
            >
              <h3>Carga de archivos</h3>
            </b-card-header>
            <b-card-body class="custom-card-body">
              <SimpleAlert
                type="light-gray"
                text="Tipos de archivos soportados: .xml, .pdf"
              >
                <template slot="icon">
                  <span class="modal-icon mr-2">
                    <i class="ri-error-warning-line"></i>
                  </span>
                </template>
              </SimpleAlert>

              <ul class="list list-unstyled">
                <li>
                  Tipo de comprobante:
                  <span class="text-secondary">Nota de crédito</span>
                </li>
                <li>
                  <div class="form-group">
                    <label for="paymentDate">Fecha de comprobante *</label>
                    <FormDatepicker
                      label-id="paymentDate"
                      :show-calendar="showIssueDate"
                      style="border: 0"
                      class="custom-form-control"
                      :max="new Date()"
                      @show="showIssueDate = true"
                      @hidden="showIssueDate = false"
                      v-model="issueDate"
                    />
                    <FormError
                      v-if="$v.issueDate.$error && !$v.issueDate.required"
                      message="Fecha de comprobante es requerida"
                    />
                  </div>
                  <div class="form-group custom-form">
                    <label for="voucherCode">Número de comprobante *</label>
                    <input
                      id="voucherCode"
                      type="text"
                      maxlength="11"
                      v-model.trim="voucherCode"
                      autocomplete="off"
                      placeholder="Ingresar número de comprobante"
                      class="custom-form-control"
                      :class="{
                        'custom-form-control-error': $v.voucherCode.$error,
                      }"
                    />
                    <FormError
                      v-if="$v.voucherCode.$error && !$v.voucherCode.required"
                      message="Número de comprobante es requerido"
                    />
                  </div>
                  <div class="form-group custom-form">
                    <label for="voucherCode">Monto total *</label>
                    <input
                      id="voucherCode"
                      type="text"
                      maxlength="11"
                      v-model.trim="total"
                      autocomplete="off"
                      placeholder="Ingresar total"
                      class="custom-form-control"
                      :class="{
                        'custom-form-control-error': $v.total.$error,
                      }"
                    />
                    <FormError
                      v-if="$v.total.$error && !$v.total.required"
                      message="Total es requerido"
                    />
                    <FormError
                      v-if="$v.total.$error && !$v.total.decimal"
                      message="Ingrese un monto válido"
                    />
                  </div>
                </li>
                <li>
                  ID de pago:
                  <span class="text-secondary">{{ payment.code }}</span>
                </li>
                <li>
                  Monto de pago:
                  <span
                    class="text-secondary"
                    v-decimal:pen="payment.amount"
                  ></span>
                </li>
              </ul>
              <div class="custom-form">
                <FileInput
                  :acceptFile="['.pdf', '.xml']"
                  id-file="input-files"
                  @onChange="onChange"
                  :multiple="true"
                />
                <form-error
                  message="Debe seleccionar al menos un archivo, como máximo 2"
                  v-if="$v.files.$error"
                ></form-error>
                <div class="form-group-buttons text-right">
                  <router-link
                    :to="{ name: 'payments' }"
                    class="button button-cancel"
                  >
                    Cancelar
                  </router-link>
                  <button
                    class="button button-primary"
                    type="submit"
                    @click="submit"
                  >
                    Procesar
                  </button>
                </div>
                <div class="form-errors mt-3" v-if="errors.length > 0">
                  <Alert
                    text="Por favor, solucione los siguientes problemas o caso contrario genera una nota de crédito para cargar una nueva factura."
                    :icon="
                      require('@/core/assets/images/components/icon-alert-yellow.svg')
                    "
                    class="warning mb-2"
                  />
                  <b
                    ><span class="error">Errores: {{ errors.length }}</span></b
                  ><br />

                  <span
                    v-for="(e, i) in errors"
                    class="error"
                    :key="`${i}-error`"
                    >{{ e }}<br
                  /></span>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  decimal,
  maxLength,
  minLength,
  required,
} from "vuelidate/lib/validators";
import { PaymentService, VoucherService } from "@/core/services";
import { Constants } from "@/core/utils";
import { FormDatepicker, FileInput, SimpleAlert, FormError } from "wize-admin";

export default {
  name: "UploadVoucherPage",
  props: {
    code: {
      type: String,
      required: false,
    },
  },
  components: {
    FormDatepicker,
    SimpleAlert,
    FileInput,
    FormError,
  },
  data() {
    return {
      payment: {},
      files: [],
      errors: [],
      showIssueDate: false,
      issueDate: null,
      voucherCode: null,
      total: null,
    };
  },
  validations: {
    files: {
      minLength: minLength(1),
      maxLength: maxLength(2),
      required,
    },
    issueDate: {
      required,
    },
    voucherCode: {
      required,
    },
    total: {
      required,
      decimal,
    },
  },
  methods: {
    onChange(files) {
      this.$v.files.$reset();
      this.files = files;
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      await this.$store.dispatch("app/loading", true);
      this.errors = [];
      const data = {
        code: this.code,
        files: this.files,
        type: Constants.VoucherType.CREDIT_NOTE.key,
        total: this.total,
        voucherCode: this.voucherCode,
        issueDate: this.issueDate,
      };
      try {
        const resp = await VoucherService.uploadVoucher(data);
        if (resp.payload.messages && resp.payload.messages.length > 0) {
          this.errors = resp.payload.messages;
          return;
        }
        this.$router.push({ name: "payments" });
      } catch (e) {
        this.errors = [];
        if (e.errors && e.errors.message) this.errors.push(e.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
  },
  async created() {
    try {
      await this.$store.dispatch("app/loading", true);
      const resp = await PaymentService.getPayment(this.code);
      this.payment = resp.payload;
    } catch (e) {
      console.error(e);
      this.$router.push({ name: "payments" });
    } finally {
      await this.$store.dispatch("app/loading", false);
    }
  },
};
</script>

<style lang="stylus" scoped>
@import '../Styles/voucher.styl';
</style>
