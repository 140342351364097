<template>
  <b-modal
    v-model="showLocal"
    @hidden="hide"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    hide-header
    size="lg"
    id="rejected-reason-modal"
  >
    <div class="modal-header d-flex justify-content-between align-items-center">
      <span class="modal-title">Motivo de rechazo</span>
      <b-button class="modal-close-icon" @click="hideModal" aria-label="Close">
        <i class="ri-close-large-line"></i>
      </b-button>
    </div>

    <section class="modal-body-container">
      <div class="text-center">
        <p class="my-0">El comprobante fue rechazado por</p>
        <p v-html="rejectReason"></p>
      </div>
    </section>
  </b-modal>
</template>
<script>
import { ModalMixin } from "wize-admin";
import { required } from "vuelidate/lib/validators";

export default {
  name: "RejectReasonModal",
  props: {
    rejectReason: String,
  },
  data() {
    return {
      reasonId: null,
      reasons: [],
    };
  },
  mixins: [ModalMixin],
  methods: {
    confirm() {
      const self = this;
      self.$v.$touch();
      if (self.$v.$error) return;

      this.$emit("confirm");
    },
    hideModal() {
      this.reasonId = null;
      this.$emit("hide");
    },
  },
  validations: {
    reasonId: { required },
  },
};
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/components/rejected-reason-modal.styl"
</style>
