<template>
  <header class="admin-header">
    <div class="container d-flex justify-content-between align-items-center py-0">
      <div>
        <router-link
          to="/"
          class="d-flex align-items-center text-decoration-none"
        >
          <img
            src="@/core/assets/images/welcome/logo.svg"
            alt="Logo panel de administración"
            class="logo"
          />
          <!-- TODO replace app icon -->
          <h2 class="title text-white font-weight-bold mt-1">NAGAw</h2>
        </router-link>
      </div>

      <div class="d-flex align-items-center">
        <UserDropdown />
      </div>
    </div>
  </header>
</template>

<script>
import { UserDropdown } from 'wize-admin'

export default {
  name: "AdminHeader",
  components: {
    UserDropdown
  }
};
</script>

<style lang="stylus" scoped>
@import "../Styles/header";
</style>
